import React, { useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Introduction from './components/Introduction';
import Neighborhoods from './components/Neighborhoods';
import Housing from './components/Housing';
import Mikvaot from './components/Mikvaot';
import KosherFood from './components/KosherFood';
import Networking from './components/Networking';
import News from './components/News';
import Schools from './components/Schools';
import Visiting from './components/Visiting';
import Resources from './components/Resources';
import Footer from './components/Footer';
import AdUnit from './components/AdUnit';
import { trackPageView } from './utils/Analytics';
import ConsentBanner from './components/ConsentBanner';

function App() {
  useEffect(() => {
    // Track initial page view
    trackPageView(window.location.pathname);
    
    // Smooth scroll for anchor links
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();
        const target = document.querySelector(this.getAttribute('href'));
        if (target) {
          target.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
      });
    });
  }, []);

  return (
    <div className="App">
      <Header />
      <main>
        <article itemScope itemType="http://schema.org/Guide">
          <h1 className="visually-hidden">Jewish Seattle Guide - Complete Resource for Jewish Life in Seattle</h1>
          <meta itemProp="author" content="Dina Levitan" />
          <meta itemProp="description" content="A comprehensive guide to Jewish life in Seattle, WA" />
          <meta itemProp="datePublished" content="2025-03-30" />
          <meta itemProp="dateModified" content="2025-04-09" />
          
          <section id="introduction" className="section">
            <Introduction />
          </section>

          <div className="ad-container">
            <AdUnit 
              style={{ 
                margin: '2rem auto',
                minHeight: '250px',
                maxWidth: '800px'
              }}
            />
          </div>

          <section id="neighborhoods" className="section">
            <Neighborhoods />
          </section>

          <section id="housing" className="section">
            <Housing />
          </section>

          <section id="mikvaot" className="section">
            <Mikvaot />
          </section>

          <section id="kosher-food" className="section">
            <KosherFood />
          </section>

          <section id="networking" className="section">
            <Networking />
          </section>

          <section id="news" className="section">
            <News />
          </section>

          <section id="schools" className="section">
            <Schools />
          </section>

          <section id="visiting" className="section">
            <Visiting />
          </section>

          <section id="resources" className="section">
            <Resources />
          </section>

          <div className="ad-container">
            <AdUnit 
              style={{ 
                margin: '2rem auto',
                minHeight: '250px',
                maxWidth: '800px'
              }}
            />
          </div>
        </article>
      </main>
      <Footer />
      <ConsentBanner />
      <button 
        className="back-to-top" 
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        aria-label="Back to top"
      >
        ↑
      </button>
    </div>
  );
}

export default App;
